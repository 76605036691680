<template>
  <div
    v-loading.fullscreen.lock="fullscreenLoading"
    class="main-article"
    element-loading-text="数据加载中..."
  >
    <div class="article__heading">
      <div class="article__heading__title">
        {{ src.title }}
      </div>
    </div>
    <div v-if="fileTypeResult.isPdf" ref="content" class="node-article-content clearfix">
      <Pdf v-for="i in numPages" :key="i" :src="src" :page="i" />
    </div>
    <div v-else-if="fileTypeResult.isImage" class="node-article-content clearfix">
      <el-image :src="src"></el-image>
    </div>
    <div v-else class="doc-view">
      <iframe :src="src" width="100%" height="100%"></iframe>
    </div>
  </div>
</template>

<script>
// import Pdf from "vue-pdf";

export default {
  components: {
    Pdf,
  },
  data() {
    return {
      src: "",
      fullscreenLoading: true,
      numPages: undefined,
      fileTypeResult: { isPdf: false, isImage: false, isWord: false },
    };
  },
  created() {
    const item = this.$route.query;
    console.log(this.$route.query);
    document.title = item.fileName;
    this.fileTypeResult = this.getFileType(item.fileName);
    if (this.fileTypeResult.isPdf) {
      console.log("打开pdf类型的");
      this.src = Pdf.createLoadingTask(item.fileUrl);
      this.src.promise.then((pdf) => {
        this.numPages = pdf.numPages;
        this.fullscreenLoading = false;
      });
    } else if (this.fileTypeResult.isImage) {
      console.log("打开Image类型的");
      this.src = item.fileUrl;
      this.fullscreenLoading = false;
    } else if (this.fileTypeResult.isWord) {
      /*
      https://view.officeapps.live.com/op/view.aspx?src= 是官网提供的方法
      官方网址：https://www.microsoft.com/en-us/microsoft-365/blog/2013/04/10/office-web-viewer-view-office-documents-in-a-browser/
      src 后面是你文档的地址 或者是你下载的地址
      注意：

      要有域名
      要在公网能连接到
      最好不要有什么访问权限 当然 带参数是可以
      */
      console.log("打开Word类型的");
      this.src = "https://view.officeapps.live.com/op/view.aspx?src=" + item.fileUrl;
      this.fullscreenLoading = false;
    }
  },
  mounted() {},
  methods: {
    //文件上传验证.
    getFileType(fileName) {
      let pdfTypes = ["pdf", "PDF"];
      let imageTypes = [
        "bmp",
        "jpg",
        "JPG",
        "jpeg",
        "JPEG",
        "png",
        "PNG",
        "tif",
        "gif",
        "GIF",
      ];
      let wordTypes = ["doc", "docx", "xls", "xlsx"];
      let fileContentType = fileName.match(/^(.*)(\.)(.{1,8})$/)[3];
      console.log("filecontent", fileContentType);

      let isPdf = false;
      let isImage = false;
      let isWord = false;

      for (var i in pdfTypes) {
        if (fileContentType.toLowerCase() == pdfTypes[i].toLowerCase()) {
          isPdf = true;
        }
      }
      // 先去判断是否是pdf 再去判断是否是图片 再去判断是否是word
      if (!isPdf) {
        for (var i in imageTypes) {
          if (fileContentType.toLowerCase() == imageTypes[i].toLowerCase()) {
            isImage = true;
          }
        }
      }
      // 前面这2个都是false才检查这个
      if (!isPdf && !isImage) {
        for (var i in wordTypes) {
          if (fileContentType.toLowerCase() == wordTypes[i].toLowerCase()) {
            isWord = true;
          }
        }
      }
      // 如果是PDF的则用PDF打开，如果是Image的则用image显示，否则就是不能预览的类型
      return { isPdf: isPdf, isImage: isImage, isWord: isWord };
    },
  },
};
</script>

<style lang="scss" scoped>
.clearfix {
  &:before {
    display: table;
    content: "";
    clear: both;
  }

  &:after {
    display: table;
    content: "";
    clear: both;
  }
}

.main-article {
  padding: 20px;
  margin: 0 auto;
  display: block;
  width: 740px;
  background: #fff;
}

.article__heading {
  position: relative;
  padding: 0 0 20px;
  overflow: hidden;
}

.article__heading__title {
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-size: 32px;
  line-height: 48px;
  font-weight: 600;
  color: #333;
  overflow: hidden;
}

.node-article-content {
  margin: 20px 0 0;
  font-size: 16px;
  color: #333;
  letter-spacing: 0.5px;
  line-height: 28px;
  margin-bottom: 30px;
  font-family: medium-content-serif-font, Georgia, Cambria, "Times New Roman", Times,
    serif;

  & > :last-child {
    margin-bottom: 0;
  }

  b,
  strong {
    font-weight: inherit;
    font-weight: bolder;
  }

  img {
    max-width: 100%;
    display: block;
    margin: 0 auto;
  }

  p {
    font-weight: 400;
    font-style: normal;
    font-size: 21px;
    line-height: 1.58;
    letter-spacing: -0.003em;
  }

  ul {
    margin-bottom: 30px;
  }

  li {
    --x-height-multiplier: 0.375;
    --baseline-multiplier: 0.17;

    letter-spacing: 0.01rem;
    font-weight: 400;
    font-style: normal;
    font-size: 21px;
    line-height: 1.58;
    letter-spacing: -0.003em;
    margin-left: 30px;
    margin-bottom: 14px;
  }

  a {
    text-decoration: none;
    background-repeat: repeat-x;
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.84) 100%,
      rgba(0, 0, 0, 0) 0
    );
    background-size: 1px 1px;
    background-position: 0 calc(1em + 1px);
    padding: 0 6px;
  }

  code {
    background: rgba(0, 0, 0, 0.05);
    padding: 3px 4px;
    margin: 0 2px;
    font-size: 16px;
    display: inline-block;
  }

  img {
    border: 0;
  }

  /* 解决 IE6-7 图片缩放锯齿问题 */
  img {
    -ms-interpolation-mode: bicubic;
  }

  blockquote {
    --x-height-multiplier: 0.375;
    --baseline-multiplier: 0.17;
    font-family: medium-content-serif-font, Georgia, Cambria, "Times New Roman", Times,
      serif;
    letter-spacing: 0.01rem;
    font-weight: 400;
    font-style: italic;
    font-size: 21px;
    line-height: 1.58;
    letter-spacing: -0.003em;
    border-left: 3px solid rgba(0, 0, 0, 0.84);
    padding-left: 20px;
    margin-left: -23px;
    padding-bottom: 2px;
  }

  a {
    text-decoration: none;
  }

  h2,
  h3,
  h4 {
    font-size: 34px;
    line-height: 1.15;
    letter-spacing: -0.015em;
    margin: 53px 0 0;
  }

  h4 {
    font-size: 26px;
  }
  .doc-view {
    width: 100vw;
    height: 100vh;
    background: chartreuse;
  }
}
</style>
